/* Reusable, object-oriented CSS base components for all Zulip pages
   (both webapp and portico). */

.new-style label.checkbox {
        padding: 0px;
        display: inline-block;
        position: relative;
        vertical-align: top;
    }

.new-style label.checkbox input[type=checkbox] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
        }

.new-style label.checkbox input[type=checkbox] ~ span {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                top: -2px;

                padding: 2px;
                margin: 0px 5px 0px 0px;
                height: 10px;
                width: 10px;

                font-weight: 300;
                line-height: 0.8;
                font-size: 1.3rem;
                text-align: center;
                border: 1px solid hsla(0, 0%, 0%, 0.6);

                border-radius: 4px;
                -webkit-filter: brightness(0.80);
                        filter: brightness(0.80);

                cursor: pointer;
            }

.new-style label.checkbox input[type=checkbox]:checked ~ span {
                background-image: url(/static/images/checkbox.svg);
                background-size: 85%;
                background-position: 50% 50%;
                background-repeat: no-repeat;
            }

.new-style label.checkbox input[type=checkbox]:focus ~ span {
                outline: 1px dotted;
                outline: -webkit-focus-ring-color auto 5px;
            }

.new-style label.checkbox input[type=checkbox]:disabled ~ span {
                opacity: 0.50;
                cursor: default;
            }

.new-style a.no-style {
        color: inherit;
    }

a.no-underline,
a.no-underline:hover {
    text-decoration: none;
}

.half-opacity {
    opacity: 0.5;
}

.italic {
    font-style: italic;
}

.simplebar-track .simplebar-scrollbar::before {
        background-color: hsl(0, 0%, 0%);
        box-shadow: 0px 0px 0px 1px hsla(0, 0%, 100%, 0.33);
    }

.simplebar-track.simplebar-vertical {
        -webkit-transition: width 0.2s ease 1s;
        transition: width 0.2s ease 1s;
    }

.simplebar-track.simplebar-vertical.simplebar-hover {
            width: 15px;
            -webkit-transition: width 0.2s ease;
            transition: width 0.2s ease;
        }

.simplebar-track.simplebar-vertical .simplebar-scrollbar {
            -webkit-transition: width 0.2s ease 1s;
            transition: width 0.2s ease 1s;
        }

.simplebar-track.simplebar-vertical .simplebar-scrollbar.simplebar-hover {
                width: 11px;
                -webkit-transition: width 0.2s ease;
                transition: width 0.2s ease;
            }

.simplebar-track.simplebar-horizontal {
        -webkit-transition: height 0.2s ease 1s;
        transition: height 0.2s ease 1s;
    }

.simplebar-track.simplebar-horizontal.simplebar-hover {
            height: 15px;
            -webkit-transition: height 0.2s ease;
            transition: height 0.2s ease;
        }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
            -webkit-transition: height 0.2s ease 1s;
            transition: height 0.2s ease 1s;
        }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar.simplebar-hover {
                height: 11px;
                -webkit-transition: height 0.2s ease;
                transition: height 0.2s ease;
            }

/* -- flex forms -- */
.flex-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
}
.flex-row * {
        box-sizing: border-box;
    }
.flex-row input[type=text],
    .flex-row input[type=password] {
        height: auto;
        width: 100%;
    }
.flex-row.normal {
        width: 500px;
    }
.flex-row .field {
        margin: 10px;
        width: 100%;
    }

body {
    background-color: hsl(0, 0%, 98%);
}

.noscroll {
    overflow: hidden;
    position: fixed;
}

.digest-address-link {
    margin-top: 10px;
    color: hsl(0, 0%, 60%);
    font-size: 12px;
    text-align: center;
}

.mac-cmd-key {
    font-size: 15px;
    font-family: monospace;
}

.container-fluid {
    padding: 0px;
    min-height: 100%;

    /* Hack for IE */
    height: auto !important;
    height: 100%;

    margin: 0 auto -56px;
}

.normal {
    font-weight: normal;
}

.navbar {
    margin-bottom: 0px;
}

.navbar-inner {
    border-radius: 0px;
}

.header {
    position: relative;

    padding: 6px 0px;
    height: 29px;

    background-color: hsl(0, 0%, 100%);
    box-shadow: 0px 0px 4px hsla(0, 0%, 0%, 0.1);

    z-index: 100;
}

.navbar.footer .nav > li {
    line-height: 56px;
}

.navbar.footer .nav > li > a {
    padding-top: 0;
    padding-bottom: 0;
}

.push {
    height: 56px;
}

.breakpoint {
    display: inline-block;
}

html {
    height: 100%;
}

body {
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
    line-height: 150%;
    height: 100%;
    font-weight: 300;
    font-size: 17px;
}

/* The API tabbed content tends to have a lot of code blocks,
   which look nicer against a different background */
.api-center .code-section .blocks {
    background-color: hsl(0, 0%, 98%);
}

.api-center .code-section ul.nav li.active {
    background-color: hsl(0, 0%, 98%);
    border-bottom: 1px solid hsl(0, 0%, 98%);
}

/* The Help Center tabbed content tends to be a lot short sentences.
   We also want the Help Center tabbed content to pop more. */
.help-center .code-section .blocks {
    max-width: 500px;
}

.digest-container {
    padding-top: 100px;
}

.digest-email-container {
    display: block;
    margin: 0 auto !important;
    max-width: 700px;
    padding-bottom: 30px;
}

.digest-email-html {
    padding: 20px;
    background-color: hsl(0, 0%, 100%);
    border-radius: 5px;
}

.help .app-main {
    padding: 0;
    margin-top: 59px;
}

.app.help {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: inline-flex;

    box-shadow: 0px -20px 50px hsla(0, 0%, 0%, 0.04);
    overflow: auto;

    color: hsl(0, 0%, 27%);
}

/* Markdown processor generates lots of spurious <p></p> */
.help p:empty {
    margin: 0px;
}

.help .sidebar {
    width: 300px;
    z-index: 1;
    /* 100vh - navbar - paddingTop - paddingBottom - bottomNav */
    height: calc(100vh - 59px);

    border-right: 1px solid hsl(219, 10%, 97%);
    overflow: auto;

    background-color: hsl(153, 32%, 55%);
    color: hsl(0, 0%, 100%);

    -webkit-overflow-scrolling: touch;
}

.help .sidebar .content {
    padding: 10px 20px;
}

.help .sidebar h1,
.help .sidebar h2 {
    font-weight: 400;
}

.help .sidebar h1 {
    font-size: 1.25em;
    line-height: 1.5;
    margin-bottom: 0px;
    border-bottom: 1px solid hsla(0, 0, 100%, .6);
}

.help .sidebar h1:not(:first-of-type) {
    margin-top: 20px;
}

.help .sidebar h1:first-of-type,
.help .sidebar h1.home-link {
    border-bottom: none;
}

.help .sidebar h2 {
    font-size: 1.2em;
    line-height: 1.05;
    margin-bottom: 5px;
}

.help .sidebar.slide h2 {
    cursor: pointer;
}

.help .sidebar.slide h2::before {
    display: block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;

    content: "\f107";
    margin-right: 5px;
    float: right;

    opacity: 0.5;
}

.help .sidebar h1.home-link {
    font-size: 1em;
    margin-bottom: 17px;
}

.help .sidebar h1.home-link a::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;

    content: "\f0d9";
    margin-right: 10px;
}

.help .sidebar ul {
    margin: 5px 0px 10px 12px;
}

.help .sidebar.slide ul {
    margin-left: 19px;
}

.help .sidebar.slide ul {
    display: none;
}

.help .sidebar li {
    list-style-type: none;
    font-size: 0.95em;
    font-weight: 400;
    line-height: 1.7;

    cursor: pointer;
}

.help .sidebar li a,
.help .sidebar h1 a {
    color: inherit;
    display: block;
}

.help .sidebar a.highlighted {
    background-color: hsl(152, 40%, 42%);

    /* Extend highlight to entire width of sidebar, not just link area */
    /* This has been changed from "+" to "- -" because of issue #8403.
       If the issue has been fixed, please change this back to "+" again. */
    width: calc(100% - -20px);
    margin-left: -40px;
    padding-left: 40px;
}

.help .sidebar a:active,
.help .sidebar a:hover,
.help .sidebar a:focus,
.help .sidebar a:visited {
    outline: none;
}

.app.help .hamburger {
    display: none;
}

.help-center ol,
.portico-landing.integrations ol {
    margin-left: 0;
}

.title {
    font-family: Helvetica;
    font-size: 100px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 60px;
}

#digest-page-title {
    text-align: center;
}

.lead {
    font-weight: bold;
}

.pitch {
    width: 600px;
    max-width: 100%;
}

.help-box {
    max-width: 500px;

    padding: 10px;
    margin: 10px 0px;

    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.2;

    color: hsl(0, 0%, 27%);

    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 4px;
    background-color: hsl(0, 0%, 98%);
}

.help-box .blob {
    margin-top: 0px;
}

.help-inline {
    font-weight: 400;
    font-size: 0.9rem;
}

.help-inline.text-error {
    color: hsl(1, 54%, 61%);
}

a.title {
    color: hsl(0, 0%, 0%);
}

a.title:hover {
    color: hsl(0, 0%, 50%);
    text-decoration: none;
}

.fakecontrol {
    padding-top: 5px;
    font-weight: bold;
}

img.screenshot {
    /* This makes it so screenshots are still shown
    even if they are larger than their span. */
    max-width: 100%;
}

.android-screenshot {
    margin-top: -30px;
    width: 221px;
}

.iphone-screenshot {
    width: 184px;
    margin: 0 32px;
}

.full-width-screenshot {
    width: 100%;
}

/* Silly easter egg for /integrations */
.integral {
    font-weight: normal;
    display: none;
}

.show-integral {
    position: relative;
}

.show-integral:hover::after {
    content: "dx";
    font-weight: normal;
    font-style: italic;
    font-size: 70%;
}

.integration {
    margin-top: 2em;
    padding-top: 1em;
    border-top: 1px dashed hsl(0, 0%, 0%);
}

.integration p {
    margin-top: 2em;
}

#pw_strength {
    /* Same width as a Bootstrap default text <input> with padding */
    width: 220px;
    height: 8px;
}

#registration #pw_strength {
    width: 325px;
    margin-top: 20px;
}

.def::before {
    content: " - ";
}

.api-details {
    margin-left: 2em;
}

.api-details ul {
    list-style-type: none;
}

.api-details dd {
    margin-bottom: 1em;
}

.python {
    display: none;
}

.commandline {
    display: none;
}

@media print {
    #portico-area {
        margin-left: 1.5em;
    }
}

.landing-page {
    padding: 2em 0em;
}

.landing-page-bottom {
    padding-bottom: 2em;
}

.landing-page h2,
.landing-page h4 {
    font-weight: lighter;
}

.landing-page p {
    font-size: 120%;
}

.laptop-image {
    width: 787px;
    height: 414px;
    background-image: url('/static/images/landing-page/laptop.png');
    background-size: contain;
}

.laptop-screen {
    position: relative;
    top: 32px;
    width: 507px;
}

.platform-icon {
    width: 33%;
}

.portico-page h1 {
    font-weight: 300;
}

.shaded-background {
    background-color: hsl(0, 0%, 93%);
}

.darker-background {
    background-color: hsl(0, 0%, 28%);
    color: hsl(0, 0%, 100%);
}

.alert-hidden {
    display: none;
}

label.text-error {
    display: inline;

    padding-left: 5px;
}

input.text-error {
    border-color: hsl(0, 100%, 50%);
    outline-color: hsl(0, 100%, 50%);
}

.portico-container {
    position: relative;
}

.portico-container .if-zulip-electron {
    display: none;
}

/* detect if the platform is `ZulipDesktop` and if so, hide this tag! */
.portico-container[data-platform="ZulipElectron"] .if-zulip-electron {
    display: block;
}

.footer {
    margin: 20px;
    z-index: 100;
    width: calc(100% - 40px);

    text-align: center;
}

.footer .footer-navigation {
    margin-left: 0px;
    margin-right: 0px;
}

/* -- new footer -- */
.footer {
    font-size: 0.9em;
}

.footer a {
    color: inherit !important;
}

.footer section {
    width: calc(25% - 40px);
    margin: 0px 20px;
    max-width: 150px;

    display: inline-block;
    vertical-align: top;

    text-align: left;
}

.footer section h3 {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.2;

    margin-bottom: 5px;
}

.footer section ul {
    color: hsl(0, 0%, 67%);

    list-style-type: none;
    margin-left: 0;

    font-weight: 400;
}

/* -- end new footer -- */

.header {
    padding: 15px 0;
}

.header-main .logo {
    display: block;
    text-decoration: none;
    position: relative;
    line-height: 0.8;
}

.header-main .logo .light {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;

    font-size: 1.2rem;
    font-weight: 400;
    color: hsl(0, 0%, 20%);
    opacity: 0.5;
}

.header-main .logo .brand-logo {
    width: 25px;
    margin-top: 3px;
}

.header-main .logo span {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
    margin-left: 10px;

    color: hsl(0, 0%, 100%);
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

.header-main .portico-logo {
    height: 28px;
    width: auto;
    padding: 6px 0px 6px 0px;
}

.portico-header .dropdown {
    position: relative;
    display: inline-block;
}

.portico-header .dropdown ul {
    display: none;

    position: absolute;
    right: 0px;
    margin: 10px 0 0 0;
    list-style-type: none;
    width: 200px;

    background-color: hsl(0, 0%, 100%);
    padding: 5px 0px;

    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 87%);
    box-shadow: 0px 2px 5px hsla(0, 0%, 0%, 0.1);
}

.portico-header .dropdown.show ul {
    display: block;
}

.portico-header .dropdown ul::before {
    content: "\25B2";

    position: absolute;
    top: -4px;
    right: 9px;

    font-size: 0.5em;
    color: hsl(0, 0%, 87%);

    line-height: 0;

    -webkit-transform: scale(1.8, 1);

            transform: scale(1.8, 1);
}

.portico-header .dropdown ul li {
    display: list-item;
    margin: 0;
    padding: 3px 10px;
    text-align: left;

    opacity: 1;

    cursor: pointer;
}

.portico-header .dropdown ul li:hover {
    background-color: hsl(190, 10%, 90%);
    -webkit-transition: none;
    transition: none;
}

.portico-header .dropdown ul li a {
    display: block;
    margin: 2px 0px;
    padding: 0px;

    -webkit-transition: none;

    transition: none;
    font-size: 0.9em;
    font-weight: 400;

    color: hsl(0, 0%, 27%);
}

.portico-header .dropdown ul li:hover a {
    background-color: transparent;
}

.portico-header .dropdown ul li a i {
    margin-right: 5px;
}

.portico-header .dropdown-pill {
    border: 1px solid hsla(0, 0%, 0%, 0.2);
    border-radius: 4px;
    margin-left: 10px;
    font-weight: 400;
    cursor: pointer;
}

.portico-header .dropdown-pill .realm-name {
    display: inline-block;
    vertical-align: top;

    margin: 0px 5px 0px -4px;
    padding: 1px 0px 1px 5px;

    font-size: 0.9em;
    font-weight: 600;

    line-height: 1.6;
}

.portico-page-container .portico-header .dropdown-pill .realm-name {
    margin-left: -3px;
}

.portico-header .dropdown-pill .realm-name i.fa {
    position: relative;
    top: -2px;

    font-size: 0.6em;
    margin-left: 5px;

    opacity: 0.5;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.portico-header .dropdown-pill:hover .realm-name i.fa {
    opacity: 1;
}

.portico-header .dropdown-pill .header-realm-icon {
    width: 26px;
    height: 26px;
    vertical-align: top;
    border-radius: 4px;
}

.app {
    width: 100%;
    z-index: 99;
}

.main-headline {
    background-color: hsl(218, 84%, 85%);
    background: url("/static/images/landing-page/gg.jpg");
    background-position: center;
    background-size: cover;
}

a.bottom-signup-button {
    color: hsl(0, 0%, 100%) !important;
    text-decoration: none !important;
    font-size: 20px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 300;
}

.signup-signature {
    margin-top: 20px;
    padding-bottom: 50px;
}

.devlogin_subheader {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-top: 0px;
    text-align: center;
    font-size: 16px;
}

.portico-large-text {
    font-size: 16px;
    line-height: 20px;
}

.portico-secondary-header {
    font-weight: 300;
    font-size: 25px;
}

.table.table-striped {
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0px 0px 4px hsla(0, 0%, 0%, 0.1);
}

.team-profiles {
    /* Compensate for gutter width */
    margin: -10px;
    margin-bottom: 3px;
}

.team .profile .profile-name {
    font-weight: 600;
}

.team .profile .profile-role {
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 400;
}

.team .bdfl {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.team .bdfl .profile-picture {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
            flex: 0 auto;
    width: 200px;
    margin: 10px;
}

.team .bdfl .profile-picture > img {
    height: auto;
    border-radius: 5px;
}

.team .bdfl .profile-information {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
            flex: 1;
    /* Wrap when screen is small to prevent very short line */
    min-width: 300px;
    font-size: 1.4em;
    margin: 10px 10px 0 10px;
}

.team .bdfl .profile-description {
    margin-top: 5px;
    font-weight: 400;
    font-size: 0.8em;
    opacity: 0.8;
}

.team .bdfl .profile-description p {
        /* Avoid double-applying the 1.2em font-size here */
        font-size: 1em;
    }

.team input {
    display: none;
}

.team label {
    font-size: initial;
    display: inline-block;
    padding: 10px;
    border: 1px solid transparent;
    margin: 0 0 -1px 0;
}

.team label:hover {
    cursor: pointer;
}

.team input:checked + label {
    border: 1px solid hsl(0, 0%, 93%);
    border-top: 2px solid hsl(80, 61%, 50%);
    border-bottom-color: hsl(0, 0%, 100%);
}

.contributors-list {
    margin-left: -40px;
    width: calc(100% - -80px);
}

.contributors {
    display: none;
}

/* Activated .contributors */
input#total:checked ~ #tab-total,
input#server:checked ~ #tab-server,
input#desktop:checked ~ #tab-desktop,
input#mobile:checked ~ #tab-mobile,
input#python-zulip-api:checked ~ #tab-python-zulip-api,
input#zulip-js:checked ~ #tab-zulip-js,
input#zulipbot:checked ~ #tab-zulipbot,
input#terminal:checked ~ #tab-terminal {
    border-top: 1px solid hsl(0, 0%, 93%);
    padding-top: 20px;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.contributors .person a {
    color: inherit;
}

.contributors .person {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid hsl(0, 0%, 93%);
    border-radius: 4px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.contributors .person:hover {
    border: 1px solid hsl(0, 0%, 73%);
}

.contributors .person .avatar {
    width: 60px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

.contributors .person .info {
    width: 50%;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

.avatar_img {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
    border-radius: 20%;
}

.tab-loading,
.last-updated {
    color: hsl(0, 0%, 67%);
    font-size: 0.9em !important;
    padding-top: 30px;
    text-align: center;
}

.history .sponsors {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.history .sponsors .sponsor-picture img {
    height: 170px;
    margin: 0 20px 0 20px;
    pointer-events: none;
}

.login-page-header {
    width: 100%;
}

.login-page-subheader {
    font-weight: 300;
    font-size: 24px;
    display: block;
    margin: auto;
    width: 300px;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 50px;
}

.apps-muted {
    font-weight: 300;
    font-size: 20px;
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
}

.apps-instructions-header {
    font-weight: 300;
    font-size: 25px;
}

.btn-app-download {
    font-size: 25px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.btn-direct {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 8px;
    font-size: 18px;
    border-radius: 0px;
    min-width: 300px;
}

.btn-user {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(169, 44%, 54%);
    border-style: solid;
    color: hsl(170, 40%, 41%);
}

.btn-admin {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(198, 100%, 48%);
    border-style: solid;
    color: hsl(207, 89%, 54%);
}

.login-page {
    text-align: center;
}

.login-page .alert {
    width: 320px;
    margin: auto;
    margin-top: -30px;
    margin-bottom: 15px;
    text-align: center;
}

.login-form {
    margin: auto;
    /* plus input padding. */
    width: 328px;
    margin-bottom: 10px;
}

.register-form {
    display: inline-block;
    text-align: center;
}

.register-button {
    margin-left: 10px;
}

.new-organization-button {
    margin-top: 10px;
}

input.new-organization-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.login-form .control-label,
.register-form .control-label {
    margin-bottom: 2px;
}

.login-form .direct-label {
    margin-top: 50px;
    margin-bottom: 6px;
}

.login-form .control-group,
.register-form .control-group {
    margin-right: 10px;
}

.login-forgot-password {
    float: right;
    line-height: 40px;
    position: relative;
    right: -15px;
    font-weight: 300;
}

.login-sso,
.login-social {
    max-width: 100%;
    min-width: 300px;
    margin: auto;
    text-align: center;
    margin-top: 10px;
}

.main-headline-container {
    position: relative;
    height: 100%;
    padding-top: 40px !important;
}

.main-headline-logo {
    display: block;
    width: 200px;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
}

.main-image {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 900px;
    height: auto;
    position: relative;
    bottom: -10px;
}

.main-headline-text {
    display: block;
    text-align: center;
    color: hsl(0, 0%, 0%);
    font-weight: 300;
}

.main-headline-text .tagline {
    font-size: 40px;
    line-height: 42px;
}

.main-headline-text .footnote {
    display: block;
    font-size: 18px;
    color: hsl(0, 0%, 0%);
    margin-top: 15px;
}

.main-signup-button {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 22px;
}

.app-main,
.header-main,
.footer-main {
    min-width: 350px;
    margin: 0px auto;
    padding: 0px 20px 0px 20px;
    position: relative;
}

.footer-main {
    text-align: center;
}

.hello-main {
    max-width: none;
    min-width: 0;
    padding: 0 !important;
}

.footer-navigation {
    list-style: none;
    padding-top: 16px;
}

.footer-navigation li,
.footer-navigation li a {
    display: inline-block;
    vertical-align: middle;
    color: hsl(0, 0%, 47%);
    font-size: 0.9em;
    font-weight: 400;
    cursor: pointer;
}

.footer-navigation li:hover a {
    color: hsl(0, 0%, 27%);
    text-decoration: none;
}

.little-bullet {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}

.top-links {
    text-align: right;
}

.top-links a {
    display: inline-block;
    color: hsl(0, 0%, 100%);
    padding: 2px 7px 1px 8px;
    font-weight: 600;
    font-size: 16px;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    border-radius: 4px;
}

.top-links a:hover {
    text-decoration: none;
    background-color: hsla(0, 0%, 100%, 1.0);
    color: hsl(170, 50%, 40%);
}

.centered-button {
    text-align: center;
}

.button-muted {
    display: block;
    font-size: 12px;
    color: hsl(0, 0%, 87%);
}

/* -- password reset container -- */
.password-reset {
    display: inline-block;
    text-align: left;
}

.password-reset form {
    margin: 0px;
}

.password-reset #pw_strength {
    width: 328px;
    margin-top: 20px;
}

.password-reset .control-group .control-label {
    display: block;
}

.password-reset {
    width: 328px;
}

.password-reset .pitch {
    width: auto;
}

.input-group.margin {
    margin: 10px 0px;
}

.input-group .progress {
    margin: 0;
    margin-top: 5px;
    display: inline-block;
}

.password-reset .input-group {
    margin: 15px 0px;
}

.password-reset p {
    margin: 0;
}

.password-reset .progress {
    margin: 0;
}

.password-reset #email-section .fakecontrol {
    display: inline-block;
    margin: 0;
}

.password-reset .input-group input[type=text],
.password-reset .input-group input[type=password] {
    width: calc(100% - 14px);
}

.password-reset .input-group #pw_strength {
    width: 100%;
}

.password-reset .input-group.m-t-30 {
    margin-top: 30px;
}

.center-container {
    min-height: calc(100vh - 94px);
    min-height: 700px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -wekbit-box-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
}

.center-block .submit-button-box .controls {
    margin: 0px;
}

.center-block {
    text-align: left;
    display: inline-block;
}

.center-block .pitch {
    width: 100%;
    margin-bottom: 20px;
}

.center-block .control-group {
    margin-bottom: 10px;
}

.center-block .control-group label {
    width: 100%;
    display: block;
    margin: 0;
    text-align: left;
}

.center-block .control-group .controls {
    margin: 0;
    text-align: left;
}

.center-block #send_confirm input[type=text] {
    margin-top: 20px;
}

.center-block .button {
    margin-top: 20px;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 300;
}

.help .markdown {
    background-color: hsl(0, 0%, 100%);
    width: calc(100vw - 300px);
    height: calc(100vh - 59px);
}

.error_page {
    padding: 20px 0px;
    min-height: calc(100vh - 290px);
    background-color: white;
    font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
}

.error_page .row-fluid {
    margin-top: 60px;
}

.error_page img {
    display: block;
    clear: right;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
}

.error_page .errorbox {
    margin: auto;
    border: 2px solid #64848f;
    max-width: 500px;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 0 4px #64848f;
    font-size: 18px;
}

.error_page .errorbox.config-error {
    max-width: 750px;
}

.error_page p {
    color: hsl(0, 0%, 20%);
}

.error_page .errorcontent {
    text-align: left;
    padding: 40px 40px 30px 40px;
}

.error_page .lead {
    color: #64848f;
    font-size: 35px;
    margin-bottom: 20px;
    line-height: normal;
}

.hourglass-img {
    width: initial !important;
    margin-bottom: 25px !important;
}

@media (max-width: 800px) {
    .app.help .markdown {
        width: 100vw;
    }

    .app.help .markdown .content {
        max-width: none;
        margin-left: 50px;
    }

    .app.help .hamburger {
        display: block;
        position: fixed;
        top: 70px;
        left: 9px;
        fill: hsl(0, 0%, 100%);
        z-index: 2;

        -webkit-transition: all 0.3s ease;

        transition: all 0.3s ease;
        cursor: pointer;
    }

    .app.help .sidebar {
        position: fixed;
        top: 59px;
        right: calc(100vw - 50px);

        width: 100%;
        min-width: unset;
        height: calc(100vh - 59px);

        pointer-events: none;
        overflow: hidden;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .help .sidebar .content:not(.show) {
        visibility: hidden;
    }

    .app.help .sidebar.show {
        pointer-events: initial;
        -webkit-transform: translateX(calc(100% - 50px));
                transform: translateX(calc(100% - 50px));
        overflow: auto;
    }
        .app.help .sidebar.show .content {
            visibility: visible;
        }

    .app.help .sidebar.show + .hamburger {
        -webkit-transform: translateX(calc(100vw - 50px));
                transform: translateX(calc(100vw - 50px));
    }

    .app.help .sidebar.show ~ .markdown {
        -webkit-filter: brightness(0.7);
                filter: brightness(0.7);
    }

    .app-main,
    .header-main {
        min-width: auto;
    }

    .app-main {
        padding: 0;
    }

    .help .sidebar:not(.show) a.highlighted {
        background-color: transparent;
    }
}

@media (max-width: 815px) {
    .footer {
        width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    .footer section {
        width: calc(50% - 40px);
    }
}

@media (max-width: 767px) {
    body {
        padding: 0px !important;
    }

    .input-medium,
    .input-large {
        display: block !important;
        width: 100% !important;
        min-height: 30px !important;
        margin: 0px !important;
        padding: 4px 6px !important;
        line-height: 20px !important;
        box-sizing: border-box !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .postal-envelope {
        -webkit-transform: none;
                transform: none;
    }

    .letter-form {
        text-align: center;
    }

    .punc {
        display: none;
    }

    .signup-section {
        display: inline-block;
        margin-top: 10px;
    }

    .line-break-desktop {
        display: none;
    }

    .contributors-list {
        width: 100%;
        margin-left: 0px;
    }
}

@media (max-height: 600px) {
    .password-container {
        margin-top: 10px;
    }
}

@media (max-width: 558px) {
    .team .bdfl .profile-name,
    .team .bdfl .profile-role {
        text-align: center;
    }
}

@media (max-width: 500px) {
    .help .app-main {
        margin-top: 39px;
    }

    .app.help .sidebar {
        top: 39px;
        height: calc(100vh - 39px);
    }

    .app.help .hamburger {
        top: 50px;
    }

    .help .markdown {
        height: calc(100vh - 39px);
    }

    .error_page .lead {
        font-size: 1.5em;
        margin-bottom: 10px;
    }

    .brand.logo .light {
        display: none;
    }

    .center-container {
        display: block;
    }

    .header {
        padding: 4px 0px 6px 0px;
    }

    .header-main {
        max-width: 100vw;
        padding: 0 10px;
    }

    .main-headline-text .tagline {
        font-size: 32px;
        line-height: 34px;
    }

    #apps-tabs li a {
        padding-left: 6px;
        padding-right: 6px;
    }
}

@media (max-width: 475px) {
    .footer {
        width: auto;
    }

    .footer section {
        margin: 0px 3px;
        width: calc(50% - 8px);
    }
}

@media (max-width: 400px) {
    .footer section {
        width: calc(100% - 40px);
        max-width: none;
        text-align: center;
    }
}

.emoji {
    height: 25px;
    width: 25px;
    vertical-align: text-bottom;
}

/* --- new settings styling --- */
.input-group input[type=radio],
.input-group input[type=checkbox] {
    margin: 0 10px 0 0;
}

.input-group input[type=radio] {
    position: relative;
    top: 8px;
}

.input-group label {
    padding: 5px 0px;
}

.input-group label {
    margin: 0;
    padding: 5px 0px;
}

.m-v-20 {
    margin: 20px 0px;
}

.input-group.radio {
    margin-left: 25px;
}

.input-group.grid label.inline-block {
    width: 200px;
}

.input-group.grid {
    padding: 10px 0px;
    border-bottom: 1px solid hsl(0, 0%, 87%);
}

label.label-title {
    font-weight: 600;
}

input.new-organization-button {
    margin-left: auto;
}

.input-group label.inline-block {
    /* eyeballing off-centered aesth. */
    margin-top: 1px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.button-new {
    padding: 8px 15px;
    margin: 0;
    min-width: 130px;

    font-weight: 400;

    background-color: hsl(207, 55%, 53%);
    color: hsl(0, 0%, 100%);
    outline: none;
    border: none;
    border-radius: 2px;

    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

/* -- button states -- */
.button-new:hover {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
}

.button-new:active {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
}

.button-new.sea-green {
    background-color: hsl(177, 70%, 46%);
    color: hsl(0, 0%, 100%);
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

#find_account .form-control {
    height: 30px;
}

#find_account .btn {
    height: 40px;
    border-radius: 5px;
}

.documentation-footer {
    font-size: .85rem;
    opacity: 0.8;
}

#devtools-wrapper {
    text-align: right;
}

#devtools-registration {
    float: left;
}

#devtools-registration form {
        display: inline;
    }

#devtools-page {
    max-width: 800px;
    margin: 0 auto;
}

.portico-page.error {
    padding-bottom: 0px;
}

.portico-page.error .portico-page-container {
    padding: 0;
}

#third-party-apps {
    margin-top: 20px;
    padding-right: 10px;
}

.table .json-api-example {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 300px;
    word-wrap: break-word;
}

.table .json-api-example code {
    white-space: pre-wrap;
}

html {
    min-height: 500px;
}

.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    padding: 80px 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
            justify-content: center;

    min-height: calc(100vh - 402px);
}

.m-10 {
    margin: 10px;
}

.white-box {
    position: relative;
    padding: 30px;
    min-width: 0px;

    background-color: hsl(0, 0%, 100%);
    box-shadow: 0px 0px 4px hsla(0, 0%, 0%, 0.1);
    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 4px;
}

.bottom-text {
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    font-size: 0.9em;
}

.bottom-text a {
        color: hsl(164, 100%, 23%);
    }

.bottom-text a:hover {
            text-decoration: none;
            color: hsl(156, 62%, 61%);
        }

.grey {
    color: hsl(0, 0%, 67%);
}

.find-account-page-container #find_account i {
    font-size: 0.8em;
}

.login-page-container .group {
    margin: 0px 20px;
    display: inline-block;
}

.app-main .login-page-header {
    font-size: 1.5em;
    font-weight: 300;
    margin: 0px;
    height: 0px;
}

.login-page-container.dev-login {
    margin-top: 20px;
    border: none;
}

.login-page-container.dev-login .login-form {
    width: auto;
}

.new-style .login-page-container .alert,
.register-page-container .new-style .alert {
    margin: 0;
    text-align: left;
    font-size: 0.7em;
    font-weight: 600;
}

.register-page-container.closed-realm .right-side {
    display: none;
}

.register-page-container.closed-realm .left-side {
    border-right: none;
}

.register-page-container .invite-required {
    display: none;
    line-height: 1;
}

.register-page-container .invite-required i {
    margin-right: 5px;
}

.register-page-container.closed-realm .invite-required {
    display: block;

    color: hsl(0, 0%, 67%);
    font-weight: normal;
}

.new-style .inline-block {
    text-align: left;
}

.app-main .login-page-header {
    font-size: 1.5em;
    font-weight: 300;
    margin-top: 0px;
    -webkit-transform: translateX(-15px) translateY(-60px);
            transform: translateX(-15px) translateY(-60px);
    text-align: left;
}

.app-main .login-page-container.dev-login .login-page-header {
    height: auto;
    margin-top: 20px;
    -webkit-transform: none;
            transform: none;
    text-align: center;
}

.app-main .login-page-container.dev-login h2 {
    font-size: 1em;
    font-weight: 400;
}

.app-main .login-page-container .group {
    display: inline-block;
    vertical-align: top;

    margin: 0px 20px;
}

.app-main.forgot-password-container {
    font-weight: 400;
}

.app-main.find-account-page-container {
    width: 500px;
    font-weight: 400;
}

.app-main.goto-account-page-container {
    width: 500px;
    font-weight: 400;
}

.app-main.confirm-continue-page-container {
    width: 400px;
    font-weight: 400;
}

.app-main.confirm-continue-page-container .white-box {
    min-width: 365px;
}

.app-main.confirm-continue-page-container .form-inline {
    display: inline-block;
}

.app-main.confirm-continue-page-container .form-inline .outline {
    border: 2px inset hsl(213, 23%, 25%);
    background-color: hsl(0, 0%, 100%);
    color: hsl(213, 23%, 25%);
    padding: 13px 20px 11px 20px;
}

.app-main.confirm-continue-page-container .form-inline .outline:hover {
    background-color: hsl(213, 65%, 97%);
}

.app-main.confirm-continue-page-container p {
    font-size: 19px;
}

.find-account-page-container h3,
.forgot-password-container h3 {
    margin-top: 0px;

    font-weight: 300;
    font-size: 2em;
}

.forgot-password-container h3 {
    margin-bottom: 0px;
}

.forgot-password-container {
    width: 503px;
}

.forgot-password-container form {
    margin-bottom: 0px;
}

.deactivated-realm-container {
    width: 503px;
}

.header {
    color: hsl(0, 0%, 27%);
}

.header {
    background-color: hsl(0, 0%, 100%);
    position: fixed;
    width: 100%;
    top: 0;
}

.header .top-links a,
.header .header-main .logo span {
    color: hsl(0, 0%, 27%);
}

.header .header-main .logo .brand-logo circle {
    fill: hsl(0, 0%, 27%) !important;
}


.new-style {
}

.register-form.new-style {
    text-align: left;
    color: hsl(0, 0%, 45%);
}

.register-form #errors {
    font-size: 0.8em;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 10px;
}

.register-form #errors:empty {
    margin-top: 0px;
}

.account-accept-terms-page .terms-of-service .input-group {
    width: 450px;
    margin: 0 auto 10px;
}

.register-account .terms-of-service .input-group {
    width: 330px;
    margin: 0 auto 10px;
}

.register-account .terms-of-service .text-error {
    position: relative;
    display: block;
    top: -5px;
    padding: 0;
    height: 0;

    font-size: 0.7em;
    font-weight: 600;
}

.account-creation {
    font-weight: 400;
}

.account-creation .pitch {
    margin-bottom: 0px;
}

.account-creation .alert.alert-info {
    padding: 8px 14px;
    text-align: left;

    font-size: 1em;
    line-height: 1.3;
}

.account-creation .white-box p:last-of-type {
    margin-bottom: 0px;
}

.relative {
    position: relative;
}

.new-style .input-box {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

#login_form .input-box {
    display: block;
}

.new-style .form-inline {
    margin: 0px;
}

.new-style button {
    display: inline-block;
    vertical-align: top;
    margin-top: 25px;
}

.new-style button:focus {
    outline: 3px solid hsl(213, 81%, 79%);
}

.new-style button.full-width {
    width: 100%;
}

.register-form button {
    margin-top: 22px;
}

.register-page .register-form .new-organization-button {
    margin-top: 25px;
}

.new-style .alert:not(.alert-info) {
    padding: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.7em;
    line-height: 1.2;
    background-color: transparent;

    border: none;
    color: hsl(0, 44%, 54%);
}

.new-style .right-side .alert {
    max-width: 328px;
}

.new-style .alert.alert-error {
    text-align: left;
}

.new-style .input-box input[type=text],
.new-style .input-box input[type=email],
.new-style .input-box input[type=password] {
    padding: 9px 32px 11px 12px;
    margin: 25px 0 4px;

    font-family: "Source Sans Pro";
    font-size: 1.2rem;
    line-height: normal;
    height: auto;

    width: 280px;

    border: 1px solid hsl(0, 0%, 87%);
    box-shadow: none;
    border-radius: 4px;

    -webkit-transition: border 0.3s ease;

    transition: border 0.3s ease;
}

.new-style .input-box input[type=email] {
    margin-bottom: 10px;
}

.new-style .input-box label {
    position: absolute;
    top: 0;
    left: 0;

    margin-top: 1px;

    -webkit-transition: all 0.3s ease;

    transition: all 0.3s ease;
}

.new-style .input-box.moving-label input[type=text]:invalid + label,
.new-style .input-box.moving-label input[type=email]:invalid + label,
.new-style .input-box.moving-label input[type=password]:invalid + label {
    -webkit-transform: translateY(35px) translateX(14px);
            transform: translateY(35px) translateX(14px);
    font-size: 1.2rem;
    font-weight: 400;
    color: hsl(0, 0%, 67%);

    pointer-events: none;
}

.new-style .input-box input[type=text]:focus:invalid,
.new-style .input-box input[type=email]:focus:invalid,
.new-style .input-box input[type=password]:focus:invalid {
    box-shadow: none;
    color: hsl(0, 0%, 27%);
}

.new-style .input-box input[type=text]:focus,
.new-style .input-box input[type=email]:focus,
.new-style .input-box input[type=password]:focus {
    border: 1px solid hsl(0, 0%, 53%);
}

.new-style .input-box label,
.new-style .input-box input[type=text]:focus + label,
.new-style .input-box input[type=email]:focus + label,
.new-style .input-box input[type=password]:focus + label,
.new-style .input-box input[type=text]:valid + label,
.new-style .input-box input[type=email]:valid + label,
.new-style .input-box input[type=password]:valid + label {
    left: 0px;
    -webkit-transform: translateY(-0px) translateX(0px);
            transform: translateY(-0px) translateX(0px);
    pointer-events: auto;

    font-size: 1rem;
    font-weight: 600;
    color: hsl(0, 0%, 27%);
}

.new-style .input-box p.text-error {
    display: block;
    padding: 0px;

    color: hsl(0, 54%, 61%);
    font-size: 0.7em;
    font-weight: 600;
    text-align: left;
    height: 0;

    position: relative;
    top: -4px;
    left: 1px;
}

.new-style .input-box label.text-error {
    display: block;

    top: 66px;
    color: hsl(0, 54%, 61%);
    font-size: 0.7em;
    font-weight: 600;
    padding-left: 0px;
}

.new-style .get-started {
    font-size: 2.5rem;
    text-align: center;
    color: hsl(0, 0%, 40%);
    margin-bottom: 20px;
}

.new-style button {
    display: inline-block;
    vertical-align: top;
    padding: 13px 22px 13px 22px;

    font-family: "Source Sans Pro";

    font-size: 1.2rem;
    font-weight: 400;
    box-sizing: border-box;
    outline: none;
    color: hsl(0, 0%, 100%);
    background-color: hsl(213, 23%, 25%);

    border: none;
    border-radius: 4px;

    -webkit-transition: all 0.3s ease;

    transition: all 0.3s ease;
}

.new-style button:hover {
    background-color: hsl(213, 33%, 31%);
}

.new-style button:active {
    background-color: hsl(214, 28%, 16%);
}

/* -- /accounts/register/ page -- */
.portico-page .pitch h1 {
    margin-bottom: 0px;
}

.portico-page .pitch p {
    font-weight: 400;
    color: hsl(0, 0%, 67%);
}

.register-account .pitch,
.account-accept-terms-page .pitch {
    margin-bottom: 5px;
    text-align: center;
}

.login-page-container input[type=submit] {
    color: hsl(0, 0%, 67%);
    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 4px;
    background-color: transparent;

    -webkit-transition: color 0.3s ease, border 0.3s ease;

    transition: color 0.3s ease, border 0.3s ease;
}

.login-page-container input[type=submit]:hover {
    border-color: hsl(0, 0%, 53%);
    color: hsl(0, 0%, 27%);
}

.login-page-container input[type=submit].btn-admin {
    border-color: hsl(170, 41%, 52%);
    color: hsl(170, 41%, 52%);
}

.login-page-container input[type=submit].btn-admin:hover {
    color: hsl(156, 62%, 61%);
    border-color: hsl(156, 62%, 61%);
}

.split-view .org-header {
    text-align: left;
}

.split-view .right-side .form-inline {
    width: 328px;
}

.split-view .org-header .avatar,
.register-page-container .org-header .avatar {
    display: inline-block;
    vertical-align: top;

    width: 100px;
    height: 100px;
}

.split-view .org-header .info-box {
    display: inline-block;
    position: relative;
    margin: 20px 0px 0px 20px;
    width: calc(100% - 125px);

    /* full width minus the avatar + padding + borders */
    text-align: left;
}

.info-box .organization-name,
.info-box .organization-path {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.info-box .organization-name {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.2;
}

.info-box .organization-path {
    font-weight: 400;
    color: hsl(0, 0%, 47%);
    margin-top: 5px;
}

.new-style .lead {
    margin: 0px;
}

.confirm-email-change-page .white-box {
    max-width: 500px;
}

.portico-page .or {
    width: 100%;
    display: block;
    margin: 10px auto;
    color: hsl(0, 0%, 34%);
    font-weight: 600;
    text-align: center;
    position: relative;
    z-index: 1;
}

.portico-page .or::before {
    content: " ";

    display: inline-block;
    position: absolute;
    width: calc(100% - 5px);
    top: calc(50% - 2px);
    left: 0;
    z-index: -1;

    border-bottom: 2px solid hsl(0, 0%, 87%);
}

.portico-page .or span {
    background-color: hsl(0, 0%, 100%);
    padding: 0 5px;
}

button.login-social-button {
    width: 328px;
    padding-left: 35px;

    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: 13px 50%;
    margin-left: 0px;
    margin-top: 0px;

    color: hsl(0, 0%, 100%);
}

button.login-social-button {
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0px 1px 3px hsla(0, 0%, 0%, 0.3), 0px 0px 5px hsla(0, 0%, 0%, 0.1);
    color: hsl(0, 0%, 34%);
}

button.login-social-button:hover {
    background-color: hsl(0, 0%, 98%);
    box-shadow: 1px 2px 5px hsla(0, 0%, 0%, 0.2);
}

button.login-social-button:active {
    background-color: hsl(0, 0%, 93%);
    box-shadow: 0px 1px 1px hsla(0, 0%, 0%, 0.3);
}

.google-wrapper button.login-social-button {
    background-image: url('/static/images/landing-page/logos/googl_e-icon.png');
    width: 100%;
}

.github-wrapper::before {
    content: "\f09b";
    position: absolute;

    font-family: "FontAwesome";
    font-size: 2rem;
    color: hsl(0, 0%, 20%);
    -webkit-transform: translateX(15px) translateY(13px);
            transform: translateX(15px) translateY(13px);
}

.azuread-wrapper::before {
    content: "\f17a";
    position: absolute;

    font-family: "FontAwesome";
    font-size: 2rem;
    color: hsl(0, 0%, 20%);
    -webkit-transform: translateX(15px) translateY(13px);
            transform: translateX(15px) translateY(13px);
}

.login-page-container .right-side .actions,
.forgot-password-container .actions {
    margin: 20px 0px 0px;
    text-align: left;
}

.forgot-password-container .actions {
    line-height: 0;
}

.forgot-password-container .actions .back-to-login i {
    position: relative;
    top: 5px;

    font-size: 0.8em;
}

#find-account-section {
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.5;
    vertical-align: top;
}

#find-account-section #find-account-link {
        color: hsl(164, 100%, 23%);
    }

.split-view .actions a,
.back-to-login {
    color: hsl(164, 100%, 23%);
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.5;
    vertical-align: top;

    -webkit-transition: color 0.2s ease;

    transition: color 0.2s ease;
}

.split-view .actions a:hover,
.back-to-login:hover {
    text-decoration: none;
    color: hsl(156, 62%, 61%);
}

#registration {
    width: auto;
    padding: 0;
    margin: 30px;
}

#registration section {
    margin: 30px;
}

#registration .info-box {
    margin: 10px 0px 0px 20px;
}

#registration .info-box .organization-name {
    max-width: 228px;
}

#registration .input-box {
    display: block;
    text-align: center;
    width: 330px;
    margin: 30px auto 10px;
    position: relative;
}

#registration .input-box.with-top-space {
    margin-top: 50px;
}

#registration .input-box label {
    position: absolute;
    margin: 0;
    top: 0;
    left: 2px;
}

#registration .input-box label.static {
    width: 100%;
    text-align: left;
    position: static !important;
    margin-left: 2px;
}

#registration [for="realm_in_root_domain"] {
    font-weight: normal !important;
}

#registration .register-button-box {
    text-align: center;
}

#registration .register-button {
    margin: 25px auto 30px;
    width: 330px;
    border-radius: 4px;
}

#registration #id_team_subdomain.subdomain {
    text-align: right;
    position: relative;
    padding-right: 10px;

    width: 150px;
}

#registration #id_team_subdomain-error {
    margin-top: 5px;
    display: block;
}

#registration #id_team_subdomain.subdomain + .realm_subdomain_label {
    margin-top: 12px;
    margin-left: 180px;
    display: inline-block;

    font-weight: normal;
    font-size: inherit;
}

#registration #id_team_subdomain.subdomain {
    margin-top: 0px;
}

#registration #subdomain_section .inline-block {
    width: 100%;
}

#registration #subdomain_section .or {
    margin-top: 0px;
}

.static.org-url + #subdomain_section {
    margin-top: 0px !important;
}

.static.org-url + #subdomain_section .or {
    display: none;
}

#registration .input-box.full-width {
    width: calc(100% - 20px);
}

#registration .help-box {
    width: calc(100% - 25px);
    max-width: none;
    margin: 0 auto;
}

#registration .help-box.margin-top {
    margin-top: 21px;
    text-align: left;
}

#registration .external-host {
    margin: 25px 0px 0px -3px;
    padding: 11.5px 10px;

    font-weight: 400;
    font-size: 1.2rem;

    background-color: hsl(0, 0%, 87%);
}

#registration .center-block .pitch {
    margin-bottom: 0px;
}

#registration .input-group label {
    font-size: 1rem;
}

#registration .input-group.radio {
    margin: 0;
    padding: 0;
}

#registration .input-group label.inline-block {
    margin-top: -1px;
}

#registration .org-url {
    margin-bottom: 5px !important;
}

.split-view .right-side,
.split-view .left-side {
    display: inline-block;
    vertical-align: top;
}

.split-view .left-side {
    width: 500px;
    border-right: 1px solid hsl(0, 0%, 93%);
    position: relative;
    left: -1px;
}

.split-view .left-side + .right-side {
    border-left: 1px solid hsl(0, 0%, 93%);
    /* this is to make sure the borders of the left and right side overlap
       each other. */
    padding-left: 15px;
    margin-left: -5px;
}

.split-view .left-side .description {
    text-align: left;
    font-weight: normal;

    margin-top: 20px;
    margin-right: 10px;
}

#profile_info_section #profile_avatar {
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 8px;
        width: 120px;
        height: 120px;
        margin: 0 auto 10px;
    }

#profile_info_section #profile_full_name {
        font-size: 1.2rem;
        font-weight: 400;
    }

/* -- media queries -- */

@media (max-width: 950px) {
    .split-view .left-side {
        width: 400px;
    }
}

@media (max-width: 850px) {
    .split-view .left-side {
        width: 350px;
    }
}

@media (max-width: 815px) {
    .flex {
        min-height: calc(100vh - 530px);
    }
}

@media (max-width: 795px) {
    .register-account #registration {
        padding: 10px;
    }

    .register-page-container,
    .login-page-container {
        width: 400px;
        text-align: center;
    }

    .split-view .org-header .avatar {
        width: 50px;
        height: 50px;
    }

    .split-view .org-header .info-box {
        margin-top: 0px;
    }

    .split-view .org-header .info-box .organization-path {
        margin-top: 0px;
    }

    .split-view .left-side,
    .split-view .right-side {
        display: block;
        margin: 0 auto !important;
        max-width: 100%;
    }

    .split-view .left-side + .right-side {
        border-left: none;
        padding: 0;
        margin: 0 auto;
    }

    .split-view .left-side {
        border: none;
        margin-right: 0px;
        min-height: 0px;
        margin-bottom: 20px;
        width: 324px;
    }

    .split-view .left-side .description {
        margin: 20px 0px;
    }

    .split-view .left-side .description a {
        color: hsl(200, 100%, 36%);
    }

    .split-view .right-side {
        width: 324px;
    }
}

@media (max-width: 500px) {
    .new-style .get-started {
        font-size: 1.6em;
    }

    .app-main.register-page-container,
    .app-main.login-page-container,
    .app-main.find-account-page-container,
    .app-main.goto-account-page-container,
    .app-main.forgot-password-container {
        display: inline-block;
        padding: 20px;
        width: calc(100% - 40px);
    }

    .forgot-password-container form .input-box {
        text-align: center;
    }

    .forgot-password-container form button {
        width: 328px;
    }

    .white-box {
        box-shadow: none;
    }

    .footer-navigation li,
    .footer-navigation li a {
        font-size: 1em;
    }

    .account-creation {
        font-weight: 400;
    }

    .flex {
        /* the header becomes smaller, so comp for that. */
        min-height: calc(100vh - 505px);
    }
}

@media (max-width: 400px) {
    .flex {
        min-height: calc(100vh - 560px);
    }
}

#login_form .loader {
    display: none;
    vertical-align: top;
    position: relative;

    height: 30px;
    margin-top: -10px;
    top: 5px;
}

#source_realm_select_section {
    position: relative;
    top: 15px;
    margin-bottom: 20px;
    font-size: 22px;
}

#source_realm_select {
    font-size: 1.2rem;
    height: 45px;
    width: 325px;
}

#source_realm_select:focus {
        outline: none;
    }

.goto-account-page #realm_redirect_subdomain {
        text-align: right;
        position: relative;
        padding-right: 10px;
    }

.goto-account-page #realm_redirect_external_host {
        font-size: 20px;
        top: 13px;
        left: 5px;
        position: relative;
    }

.goto-account-page #realm_redirect_description {
        top: 15px;
        position: relative;
    }

.goto-account-page #enter-realm-button {
        margin-top: 14px;
    }

.goto-account-page #find-account-section {
        margin-top: 20px;
        text-align: center;
        font-weight: 500;
        font-size: 0.9em;
    }

.goto-account-page .find-account-link {
        color: hsl(165, 100.0%, 14.7%);
    }

#choose_email {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
            flex-direction: column;
}

#choose_email .white-box {
        min-width: 450px;
        padding: 30px 0 50px 0;
    }

#choose_email form {
        padding: 0;
        margin: 0;
    }

#choose_email form.form-horizontal:hover {
            background-color: hsl(202, 56%, 91%);
            cursor: pointer;
        }

#choose_email form.form-horizontal:hover i.fa {
                color: hsl(203, 63%, 76%);
            }

#choose_email .choose-email-box {
        padding: 13px 0;
        margin: 0 30px;
        border-bottom: 1px solid hsl(0, 0%, 95%);
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
                align-items: center;
    }

#choose_email .choose-email-box p {
            margin: 0;
            font-size: 0.8em;
        }

#choose_email .choose-email-box p:last-child {
                line-height: 1.2em;
            }

#choose_email .choose-email-box .email {
            font-size: 0.95em;
            font-weight: 500;
        }

#choose_email .choose-email-box img,
        #choose_email .choose-email-box i {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            border-radius: 3px;
        }

#choose_email .choose-email-box i.fa {
            color: hsl(0, 0%, 67%);
            text-align: center;
            line-height: 38px;
        }

#choose_email .choose-email-box i.fa::before {
                font-size: 30px;
            }

.markdown {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    overflow: auto;
}



    .markdown h1[id]::before, .markdown h2[id]::before, .markdown h3[id]::before, .markdown h4[id]::before {
            display: block;
            content: " ";
            visibility: hidden;
        }



    .markdown h2[id]::before, .markdown h3[id]::before, .markdown h4[id]::before {
            margin-top: -10px;
            height: 10px;
        }



    .markdown h1 {
        border-bottom: 1px solid hsl(0, 0%, 93%);
        padding-bottom: 10px;
        margin-bottom: 15px;
    }



    .markdown h1[id]::before {
                margin-top: -30px;
                height: 30px;
            }



    .markdown h1#using-zulip,
        .markdown h1#zulip-administration {
            font-size: 1.75em;
            padding: 10px 0;
            margin-bottom: 0px;
            line-height: 100%;
        }



    .markdown h2 {
        font-size: 1.5em;
        line-height: 1.25;
        margin: 20px 0px 5px 0px;
    }



    .markdown h3 {
        font-size: 1.25em;
        line-height: 1.25;
        opacity: 1;
        margin-bottom: 7px;
    }



    .markdown h1,
    .markdown h2,
    .markdown h3 {
        font-weight: 700;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }



    .markdown h1:hover, .markdown h2:hover, .markdown h3:hover {
            cursor: pointer;
        }



    .markdown h1:hover::after, .markdown h2:hover::after, .markdown h3:hover::after {
                display: inline-block;
                font: normal normal normal 16px/1 FontAwesome;

                cursor: pointer;
                content: "\f0c1";
                margin-left: 5px;
                vertical-align: middle;
            }



    .markdown ul,
    .markdown ol {
        margin-left: 30px;
    }



    .markdown li {
        line-height: 150%;
    }



    .markdown ol {
        counter-reset: item;
        list-style: none;
    }



    .markdown ol > li {
            counter-increment: item;
            margin-bottom: 5px;
        }



    .markdown ol > li::before {
                content: counter(item);
                display: inline-block;
                vertical-align: top;
                padding: 3px 6.5px 3px 7.5px;
                margin-right: 5px;
                background-color: hsl(170, 48%, 54%);
                color: hsl(0, 0%, 100%);
                border-radius: 100%;
                font-size: 0.9em;
                line-height: 1.1;
                text-align: center;
            }



    .markdown ol > li > p {
                display: inline-block;
                vertical-align: top;
                max-width: calc(100% - 32px);
                position: relative;
                top: -2px;
            }



    .markdown ol > li > .codehilite,
            .markdown ol > li > p:not(:first-child) {
                padding-left: 24px;
                margin-left: 5px;
            }



    .markdown ol > li .codehilite {
                background-color: hsl(0, 0%, 100%);
            }



    .markdown ol > li > ul {
                padding-left: 20px;
            }



    .markdown ol > li .warn,
            .markdown ol > li .tip,
            .markdown ol > li .keyboard_tip {
                margin: 5px 25px 5px;
            }



    .markdown ol p {
            margin: 0 0 2px;
        }



    @media (max-width: 500px) {



    .markdown ol {
            margin-left: 0px
    }
        }



    .markdown ul {
        margin: 0px 10px 15px 25px;
    }



    .markdown ul > li {
            margin: 5px 0px;
        }



    .markdown ul > li ::before {
                content: none;
            }



    .markdown .content {
        padding: 30px;
        max-width: 768px;
        background-color: hsl(0, 0%, 100%);
    }



    .markdown .content ol li p:not(:first-child) {
            display: block;
        }



    @media (max-width: 500px) {



    .markdown .content {
            padding: 10px
    }
        }



    .markdown a {
        color: hsl(176, 46%, 41%);
        font-weight: 600;
    }



    .markdown strong {
        font-weight: 600;
    }



    .markdown img {
        vertical-align: top;
        box-shadow: 0px 0px 4px hsla(0, 0%, 0%, 0.05);
        border: 1px solid hsl(0, 0%, 87%);
        border-radius: 4px;
    }



    .markdown img.inline {
            height: 1.4em;
            box-shadow: none;
        }



    .markdown img.emoji-small {
            width: 20px;
            box-shadow: none;
            border: none;
            vertical-align: sub;
        }



    .markdown img.emoji-big {
            width: 25px;
            box-shadow: none;
            border: none;
        }



    .markdown .warn,
    .markdown .tip,
    .markdown .keyboard_tip {
        position: relative;
        display: block;
        background-color: hsl(210, 22%, 96%);
        border: 1px solid hsl(210, 22%, 90%);
        border-radius: 4px;
        padding: 10px;
        margin: 5px 0px;
    }



    .markdown .warn p, .markdown .tip p, .markdown .keyboard_tip p {
            margin-bottom: 0;
        }



    .markdown .warn p:first-of-type, .markdown .tip p:first-of-type, .markdown .keyboard_tip p:first-of-type {
            display: inline;
        }



    .markdown .tip,
    .markdown .keyboard_tip {
        background-color: hsl(46, 63%, 95%);
        border: 1px solid hsl(46, 63%, 84%);
    }



    .markdown .tip::before {
        display: inline;
        content: "\f0eb   Tip:  ";
        font-family: FontAwesome, "Yantramanav", Source Sans Pro;
        font-weight: 600;
    }



    .markdown .keyboard_tip::before {
        display: inline;
        content: "\f11c   Keyboard Shortcut:  ";
        font-family: FontAwesome, "Yantramanav", Source Sans Pro;
        font-weight: 600;
    }



    .markdown .indicator {
        position: relative;
        display: inline-block;
        top: 1px;
        padding: 5px;
        border-radius: 6px;
    }



    .markdown .indicator.grey {
            border: 1px solid hsl(0, 0%, 80%);
        }



    .markdown .indicator.grey-line {
            border: 1px solid hsl(0, 0%, 80%);
            top: 2px;
        }



    .markdown .indicator.grey-line::after {
                content: '';
                background: hsl(0, 0%, 80%);
                height: 1.5px;
                width: 6px;
                display: block;
                margin: .5px -2px;
            }



    .markdown .indicator.orange {
            border: 1px solid hsl(29, 84%, 51%);
            background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, hsla(0, 0%, 100%, 0.0)), color-stop(50%, hsla(29, 84%, 51%, 1.0)));
            background: -webkit-linear-gradient(top, hsla(0, 0%, 100%, 0.0) 50%, hsla(29, 84%, 51%, 1.0) 50%);
            background: linear-gradient(to bottom, hsla(0, 0%, 100%, 0.0) 50%, hsla(29, 84%, 51%, 1.0) 50%);
        }



    .markdown .indicator.green {
            border: 1px solid hsl(106, 74%, 44%);
            background-color: hsla(106, 74%, 44%, 0.3);
        }



    .markdown .indicator.green.solid {
            background-color: hsl(106, 74%, 44%);
        }



    .markdown .code-section ol {
            margin-left: 15px;
            margin-top: 10px;
        }



    .markdown .code-section ul.nav {
            margin: 0;
        }



    .markdown .code-section ul.nav li {
                display: inline-block;
                padding: 5px 14px;
                margin: 0;

                cursor: pointer;
            }



    .markdown .code-section ul.nav li.active {
                    color: hsl(176, 46%, 41%);
                    margin-bottom: -1px;

                    border: 1px solid hsl(0, 0%, 87%);
                    border-bottom: 1px solid hsl(0, 0%, 100%);
                    border-radius: 4px 4px 0px 0px;
                }



    .markdown .code-section.no-tabs ul.nav {
            display: none;
        }



    .markdown .code-section .blocks {
            padding: 10px;
            margin-bottom: 10px;
            border: 1px solid hsl(0, 0%, 87%);
        }



    .markdown .code-section .blocks > div {
                display: none;
            }



    .markdown .code-section.has-tabs .blocks {
            border-radius: 0px 6px 6px 6px;
        }



    .markdown .code-section.no-tabs .blocks {
            border-radius: 6px;
        }



    .markdown .code-section.no-tabs .blocks > div,
        .markdown .code-section.has-tabs .blocks > .active {
            display: block;
        }



